<template>
<svg width="165" height="152" viewBox="0 0 165 152" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="165" height="152" fill="#E5E5E5"/>
<g clip-path="url(#clip0)">
<rect width="1920" height="4489" transform="translate(-1361 -803)" fill="white"/>
<g filter="url(#filter0_d)">
<rect x="-115.5" y="-83.5" width="397" height="397" rx="18.5" stroke="#455A64" stroke-width="3" shape-rendering="crispEdges"/>
</g>
<mask id="path-2-inside-1" fill="white">
<path d="M127.486 20.0143C125.696 19.2732 123.636 19.6827 122.267 21.0526L113.818 29.5022C111.947 31.3726 111.947 34.4046 113.818 36.2751C115.688 38.1455 118.721 38.1455 120.591 36.2751L120.865 36.0012V55.2307C120.865 57.8758 123.009 60.0198 125.654 60.0198C128.299 60.0198 130.443 57.8755 130.443 55.2307V24.4389C130.443 22.5018 129.276 20.7555 127.486 20.0143Z"/>
<path d="M164.213 39.8991C164.213 17.968 146.371 0.125977 124.44 0.125977C111.92 0.125977 100.735 5.94286 93.4386 15.0139H5.57719C2.93239 15.0139 0.788086 17.1582 0.788086 19.803V118.345C0.788086 120.99 2.93239 123.134 5.57719 123.134H105.299L137.135 150.705C138.023 151.474 139.141 151.875 140.271 151.874C140.945 151.874 141.624 151.732 142.261 151.441C143.966 150.662 145.06 148.96 145.06 147.085V73.8977C156.534 66.9129 164.213 54.2859 164.213 39.8991ZM135.482 136.602L110.22 114.725C109.35 113.971 108.236 113.556 107.085 113.556H10.3666V24.5924H87.7321C85.7592 29.3065 84.667 34.4773 84.667 39.8991C84.667 61.8299 102.509 79.6719 124.44 79.6719C128.27 79.6719 131.974 79.1261 135.482 78.111V136.602ZM124.44 70.0933C107.791 70.0933 94.2455 56.5483 94.2455 39.8991C94.2455 23.2499 107.791 9.7045 124.44 9.7045C141.089 9.7045 154.634 23.2496 154.634 39.8991C154.634 56.5479 141.089 70.0933 124.44 70.0933Z"/>
<path d="M24.7339 45.1992H57.1845C59.8296 45.1992 61.9736 43.0549 61.9736 40.4101C61.9736 37.7653 59.8293 35.621 57.1845 35.621H24.7339C22.0888 35.621 19.9448 37.7653 19.9448 40.4101C19.9448 43.0549 22.0888 45.1992 24.7339 45.1992Z"/>
<path d="M24.7339 64.356H63.5699C66.215 64.356 68.359 62.2117 68.359 59.5669C68.359 56.9221 66.2147 54.7778 63.5699 54.7778H24.7339C22.0888 54.7778 19.9448 56.9221 19.9448 59.5669C19.9448 62.2117 22.0888 64.356 24.7339 64.356Z"/>
<path d="M24.7339 83.5116H53.9917C56.6368 83.5116 58.7808 81.3673 58.7808 78.7225C58.7808 76.0774 56.6365 73.9334 53.9917 73.9334H24.7339C22.0888 73.9334 19.9448 76.0777 19.9448 78.7225C19.9448 81.3673 22.0888 83.5116 24.7339 83.5116Z"/>
<path d="M92.3055 93.0912H24.7339C22.0888 93.0912 19.9448 95.2355 19.9448 97.8803C19.9448 100.525 22.0891 102.669 24.7339 102.669H92.3055C94.9506 102.669 97.0946 100.525 97.0946 97.8803C97.0946 95.2355 94.9503 93.0912 92.3055 93.0912Z"/>
</mask>
<path d="M127.486 20.0143C125.696 19.2732 123.636 19.6827 122.267 21.0526L113.818 29.5022C111.947 31.3726 111.947 34.4046 113.818 36.2751C115.688 38.1455 118.721 38.1455 120.591 36.2751L120.865 36.0012V55.2307C120.865 57.8758 123.009 60.0198 125.654 60.0198C128.299 60.0198 130.443 57.8755 130.443 55.2307V24.4389C130.443 22.5018 129.276 20.7555 127.486 20.0143Z" fill="#455A64"/>
<path d="M164.213 39.8991C164.213 17.968 146.371 0.125977 124.44 0.125977C111.92 0.125977 100.735 5.94286 93.4386 15.0139H5.57719C2.93239 15.0139 0.788086 17.1582 0.788086 19.803V118.345C0.788086 120.99 2.93239 123.134 5.57719 123.134H105.299L137.135 150.705C138.023 151.474 139.141 151.875 140.271 151.874C140.945 151.874 141.624 151.732 142.261 151.441C143.966 150.662 145.06 148.96 145.06 147.085V73.8977C156.534 66.9129 164.213 54.2859 164.213 39.8991ZM135.482 136.602L110.22 114.725C109.35 113.971 108.236 113.556 107.085 113.556H10.3666V24.5924H87.7321C85.7592 29.3065 84.667 34.4773 84.667 39.8991C84.667 61.8299 102.509 79.6719 124.44 79.6719C128.27 79.6719 131.974 79.1261 135.482 78.111V136.602ZM124.44 70.0933C107.791 70.0933 94.2455 56.5483 94.2455 39.8991C94.2455 23.2499 107.791 9.7045 124.44 9.7045C141.089 9.7045 154.634 23.2496 154.634 39.8991C154.634 56.5479 141.089 70.0933 124.44 70.0933Z" fill="#455A64"/>
<path d="M24.7339 45.1992H57.1845C59.8296 45.1992 61.9736 43.0549 61.9736 40.4101C61.9736 37.7653 59.8293 35.621 57.1845 35.621H24.7339C22.0888 35.621 19.9448 37.7653 19.9448 40.4101C19.9448 43.0549 22.0888 45.1992 24.7339 45.1992Z" fill="#455A64"/>
<path d="M24.7339 64.356H63.5699C66.215 64.356 68.359 62.2117 68.359 59.5669C68.359 56.9221 66.2147 54.7778 63.5699 54.7778H24.7339C22.0888 54.7778 19.9448 56.9221 19.9448 59.5669C19.9448 62.2117 22.0888 64.356 24.7339 64.356Z" fill="#455A64"/>
<path d="M24.7339 83.5116H53.9917C56.6368 83.5116 58.7808 81.3673 58.7808 78.7225C58.7808 76.0774 56.6365 73.9334 53.9917 73.9334H24.7339C22.0888 73.9334 19.9448 76.0777 19.9448 78.7225C19.9448 81.3673 22.0888 83.5116 24.7339 83.5116Z" fill="#455A64"/>
<path d="M92.3055 93.0912H24.7339C22.0888 93.0912 19.9448 95.2355 19.9448 97.8803C19.9448 100.525 22.0891 102.669 24.7339 102.669H92.3055C94.9506 102.669 97.0946 100.525 97.0946 97.8803C97.0946 95.2355 94.9503 93.0912 92.3055 93.0912Z" fill="#455A64"/>
<path d="M127.486 20.0143C125.696 19.2732 123.636 19.6827 122.267 21.0526L113.818 29.5022C111.947 31.3726 111.947 34.4046 113.818 36.2751C115.688 38.1455 118.721 38.1455 120.591 36.2751L120.865 36.0012V55.2307C120.865 57.8758 123.009 60.0198 125.654 60.0198C128.299 60.0198 130.443 57.8755 130.443 55.2307V24.4389C130.443 22.5018 129.276 20.7555 127.486 20.0143Z" stroke="white" stroke-width="3" mask="url(#path-2-inside-1)"/>
<path d="M164.213 39.8991C164.213 17.968 146.371 0.125977 124.44 0.125977C111.92 0.125977 100.735 5.94286 93.4386 15.0139H5.57719C2.93239 15.0139 0.788086 17.1582 0.788086 19.803V118.345C0.788086 120.99 2.93239 123.134 5.57719 123.134H105.299L137.135 150.705C138.023 151.474 139.141 151.875 140.271 151.874C140.945 151.874 141.624 151.732 142.261 151.441C143.966 150.662 145.06 148.96 145.06 147.085V73.8977C156.534 66.9129 164.213 54.2859 164.213 39.8991ZM135.482 136.602L110.22 114.725C109.35 113.971 108.236 113.556 107.085 113.556H10.3666V24.5924H87.7321C85.7592 29.3065 84.667 34.4773 84.667 39.8991C84.667 61.8299 102.509 79.6719 124.44 79.6719C128.27 79.6719 131.974 79.1261 135.482 78.111V136.602ZM124.44 70.0933C107.791 70.0933 94.2455 56.5483 94.2455 39.8991C94.2455 23.2499 107.791 9.7045 124.44 9.7045C141.089 9.7045 154.634 23.2496 154.634 39.8991C154.634 56.5479 141.089 70.0933 124.44 70.0933Z" stroke="white" stroke-width="3" mask="url(#path-2-inside-1)"/>
<path d="M24.7339 45.1992H57.1845C59.8296 45.1992 61.9736 43.0549 61.9736 40.4101C61.9736 37.7653 59.8293 35.621 57.1845 35.621H24.7339C22.0888 35.621 19.9448 37.7653 19.9448 40.4101C19.9448 43.0549 22.0888 45.1992 24.7339 45.1992Z" stroke="white" stroke-width="3" mask="url(#path-2-inside-1)"/>
<path d="M24.7339 64.356H63.5699C66.215 64.356 68.359 62.2117 68.359 59.5669C68.359 56.9221 66.2147 54.7778 63.5699 54.7778H24.7339C22.0888 54.7778 19.9448 56.9221 19.9448 59.5669C19.9448 62.2117 22.0888 64.356 24.7339 64.356Z" stroke="white" stroke-width="3" mask="url(#path-2-inside-1)"/>
<path d="M24.7339 83.5116H53.9917C56.6368 83.5116 58.7808 81.3673 58.7808 78.7225C58.7808 76.0774 56.6365 73.9334 53.9917 73.9334H24.7339C22.0888 73.9334 19.9448 76.0777 19.9448 78.7225C19.9448 81.3673 22.0888 83.5116 24.7339 83.5116Z" stroke="white" stroke-width="3" mask="url(#path-2-inside-1)"/>
<path d="M92.3055 93.0912H24.7339C22.0888 93.0912 19.9448 95.2355 19.9448 97.8803C19.9448 100.525 22.0891 102.669 24.7339 102.669H92.3055C94.9506 102.669 97.0946 100.525 97.0946 97.8803C97.0946 95.2355 94.9503 93.0912 92.3055 93.0912Z" stroke="white" stroke-width="3" mask="url(#path-2-inside-1)"/>
</g>
<defs>
<filter id="filter0_d" x="-123" y="-91" width="412" height="412" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur stdDeviation="3"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
<clipPath id="clip0">
<rect width="1920" height="4489" fill="white" transform="translate(-1361 -803)"/>
</clipPath>
</defs>
</svg>

</template>
<script>
export default {
    name: 'Notification',
    data() {
        return {}
    },
    props: {
        fill: {
            default: '--color-primary'
        }
    },
}
</script>