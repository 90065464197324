<template>
  <nav-support :fillBG="fillBG" />
    <div class="w-full page-support">
    <div class="main-support">
      <section class="flex w-full mx-auto container">
        <div
          class="
            first-section
            flex flex-col
            gap-5
            mt-24
            items-center
            justify-center
            py-5
            w-full
          "
        >
          <p class="text-5xl title font-extrabold">
            What do you need help with?
          </p>
          <div class="items-center justify-center input-box flex">
            <div class="input-wraper">
              <input
                class="support-input"
                placeholder="How can we help?"
                name="search"
                type="search"
              />
            </div>
            <button class="support-button">search</button>
          </div>
        </div>
      </section>
    </div>
    <div>
      <div class="first-section flex mx-auto container">
        <section class="w-full">
          <div class="sections-boxes">
            <div v-for="section in sections" :key="section.id" class="item-box">
              <router-link :to="'/support/' + section.id" >
              <div class="inner-box-section">
                <h2 class="box-title">{{section.title}}</h2>
                <p class="box-description">
                  Ajudamos em todo o processo de iniciação
                </p>
                  <div class="go-corner" href="#">
                  <div class="go-arrow">→</div>
                </div>
              </div>
              </router-link>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
  <footer2 />
</template>
<script>
import { defineComponent } from "vue";
import Footer2 from "@/components/Footer/Main.vue";
import NavSupport from "@/components/Navbar/Support.vue";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import axios from "axios";
import { useStore } from "@/store";
import GettingStarted from "@/components/Icons/GettingStarted.vue";
import Design from "@/components/Icons/Design.vue";
import Notification from "@/components/Icons/Notification.vue";
import Settings from "@/components/Icons/Settings";
import ArrowSupport from "../components/Icons/ArrowSupport.vue";

export default defineComponent({
  components: {
    Footer2,
    NavSupport,
    GettingStarted,
    Design,
    Notification,
    Settings,
    ArrowSupport,
    ScrollTrigger,
  },
  data() {
    return {
      fillBG: false,
      hasSections: false,
    };
  },
  unmounted() {
    window.scrollTo(0, 0);
  },
  mounted() {
    const store = useStore();
    if (store.getters["support/checkEmpty"]) {
      store.dispatch("support/getSections");
      this.hasSections = true
    }else{
      this.hasSections = true
    }

    ScrollTrigger.create({
      trigger: ".main-home",
      start: "top -10%",
      end: "bottom 150%",
      onEnter: () => {
        this.fillBG = true;
      },
      onEnterBack: () => {
        this.fillBG = false;
      },
    });
  },
  computed: {
    sections() {
      return useStore().getters["support/sections"];
    },
  },
  setup() {},
});
</script>

<style scoped>
/* clears the 'X' from Internet Explorer */
input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.go-corner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 32px;
  height: 32px;
  overflow: hidden;
  top: 0;
  right: 0;
  background-color: #CED8DE;
  border-radius: 0 4px 0 32px;
}

.go-arrow {
  margin-top: -4px;
  margin-right: -4px;
  color: #263238;
  font-family: courier, sans;
}

input {
  outline: none !important;
}
button {
  outline: none !important;
}
.title {
  font-family: GothamMedium;
  color: white;
}
section {
  padding-top: 50px;
}
.main-support {
  position: relative;
  background: linear-gradient(to bottom, #263238 0%, #959a9c00 100%);
  padding-bottom: 100px;
}
.input-box {
  max-width: 752px !important;
  min-width: 752px !important;
  margin-top: 10px;
}
.input-wraper {
  flex: 1 1;
  display: inline-block;
}
.support-input {
  width: 100%;
  border: 1px solid white;
  color: #404145;
  padding: 20px 24px 20px 58px;
  border-radius: 12px 0 0 12px;
  font-family: GothamBook;
}
.support-input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-family: GothamBook;
  opacity: 1; /* Firefox */
}

.support-input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-family: GothamBook;
}

.support-input::-ms-input-placeholder {
  /* Microsoft Edge */
  font-family: GothamBook;
}
.support-button {
  border-radius: 0 12px 12px 0;
  padding: 19px 40px !important;
  height: 66px;
  font-size: 18px;
  font-family: GothamMedium;
  box-sizing: border-box;
  position: relative;
  line-height: 100%;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  transition: 0.15s ease-in-out;
  color: white;
  background-color: #263238;
  border: 1px solid transparent;
}
.input-wraper:before {
  position: absolute;
  background: url(https://fiverr-res.cloudinary.com/npm-assets/@fiverr/support_page_perseus/apps/search.3852bf0.svg)
    50% no-repeat;
  width: 20px;
  height: 20px;
  z-index: 1;
  content: "";
  display: block;
  margin: 22px 0 0 24px;
}

.title-sections {
  font-family: GothamMedium;
  font-size: 26px;
  color: #101921;
  display: block;
  padding: 20px;
  text-align: left;
}

.sections-boxes {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.item-box {
  flex: 1 1;
  min-width: 30%;
  padding: 20px;
}

.inner-box-section {
  cursor: pointer;
  height: 220px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  background-color: #263238;
  border-radius: 4px;
  padding: 32px 24px;
  margin: 12px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
}

.inner-box-section:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: -16px;
  right: -16px;
  background: #CED8DE;
  height: 32px;
  width: 32px;
  border-radius: 32px;
  transform: scale(1);
  transform-origin: 50% 50%;
  transition: transform 0.25s ease-out;
}

.inner-box-section:hover:before {
  transform: scale(32);
}

.inner-box-section:hover {
  box-shadow: 0 0.14px 2.29266px rgb(0 0 0 / 3%),
    0 0.37px 4.42626px rgb(0 0 0 / 5%), 0 3px 7px rgb(0 0 0 / 9%);
}

.inner-box-section:hover > .box-title {
  transition: all 0.3s ease-out;
  color: #263238;
}

.inner-box-section:hover > .box-description {
  transition: all 0.3s ease-out;
  color: #263238;
}

.icon-container {
  height: 100%;
  display: flex;
  align-items: center;
}

.design-box > svg {
  width: 140px;
}

.icon-container > svg {
  height: 60%;
}

.box-title {
  font-size: 22px;
  font-family: GothamMedium;
  color: #CED8DE;
  display: flex;
  text-align: center;
  align-items: flex-end;
}

.box-description {
  font-family: GothamBook;
  font-size: 18px;
  color: #CED8DE;
}

.icon-arrow {
  width: 35px;
  height: auto;
  margin-top: 20px;
  position: relative;
}

.inner-box-section:hover > .icon-arrow {
  animation: arrow-move 1s linear;
  animation-delay: 400ms;
  animation-iteration-count: 2;
}

.support-button:hover {
  opacity: 0.8;
}

@keyframes arrow-move {
  0%,
  100% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(20px, 0);
  }
}

@media screen and (max-width: 1500px) {
  .item-box {
    flex: 1 1;
    min-width: 30%;
    max-width: 100%;
    padding: 20px;
  }
  .sections-support {
    margin-bottom: 50px;
  }
  .page-support {
    height: 100% !important;
  }
}

@media screen and (max-width: 800px) {
  .item-box {
    flex: 1 1;
    min-width: 100%;
    max-width: 100%;
    padding: 20px;
  }
  .sections-boxes {
    flex-direction: column;
  }
  .input-box {
    min-width: 100% !important;
  }
  .first-section {
    padding: 0px 20px;
  }
}
</style>