<template>
  <div class="main">
    <div class="w-full h-screen main-container">
      <div class="first-section flex-1">
        <start-now-image />
      </div>
      <div class="second-section flex-2 p-52">
        <div class="topBar">
          <div class="flex logo-top">
            <router-link to="/">
              <napps-blue class="napps-logo" />
            </router-link>
          </div>
          <div class="redirect-section">
            <span class="label-account">{{ $t("login.startnow") }}</span>
            <router-link class="button" to="/startnow?redirectTo=sync">{{
              $t("navbar.startnow")
            }}</router-link>
          </div>
        </div>
        <div @keyup.enter="login">
          <h2 class="title-login">{{ $t("login.title") }}</h2>
          <div class="fqZrOB">
            <p style="margin-bottom: 3px; color: #000000" class="ysiiV">
              {{ $t("login.email") }}
            </p>
            <p
              style="margin-top: -3px; font-size: 15px; font-weight: 300"
              class="jAWKuj"
            ></p>
            <div class="liAdpv">
              <div
                class="jsBTvB"
                :style="v$.email.$error ? 'border-color: red' : ''"
              >
                <input
                  class="input-style"
                  v-model="v$.email.$model"
                  name="email"
                  :placeholder="$t('login.placeholder_email')"
                  style="font-size: 18px"
                />
              </div>
            </div>
            <p
              v-if="
                v$.email.$errors[0] &&
                v$.email.$errors[0].$validator == 'required'
              "
              class="text-left text-sm text-error"
            >
              {{ $t("login.error_email_required") }}
            </p>
            <p
              v-if="
                v$.email.$errors[0] && v$.email.$errors[0].$validator == 'email'
              "
              class="text-left text-sm text-error"
            >
              {{ $t("login.error_email_invalid") }}
            </p>
          </div>
          <div class="fqZrOB">
            <p style="margin-bottom: 3px; color: #000000" class="ysiiV">
              Password
            </p>
            <p
              style="margin-top: -3px; font-size: 15px; font-weight: 300"
              class="jAWKuj"
            ></p>
            <div class="liAdpv">
              <div
                class="jsBTvB"
                :style="v$.password.$error ? 'border-color: red' : ''"
              >
                <input
                  class="input-style"
                  v-model="v$.password.$model"
                  name="password"
                  type="password"
                  :placeholder="$t('login.placeholder_password')"
                  style="font-size: 18px"
                />
              </div>
            </div>
            <p v-if="v$.email.$errors[0]" class="text-left text-sm text-error">
              {{ $t("login.error_password_required") }}
            </p>
          </div>
          <p v-if="errorMessage" class="text-left text-sm text-error">
            {{ errorMessage }}
          </p>
          <router-link to="/forgot"
            ><div class="link-text">
              <span style="color: black"> {{ $t("login.forgot") }}</span>
            </div></router-link
          >
          <button
            v-on:click="login"
            style="width: 300px; height: auto; margin-top: 30px"
            class="
              flex flex-row
              items-center
              justify-center
              shadow
              main-button
              bg-black
              font-semibold
              focus:shadow-outline focus:outline-none
              text-white text-xl
              py-3
              px-10
              rounded
            "
          >
            <spinner v-if="showLoading" />
            Login
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, defineComponent, computed, watch, ref } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { email, required } from "@vuelidate/validators";
import { useStore } from "@/store";
import { useRouter } from "vue-router";
import Spinner from "@/components/Icons/Spinner.vue";
import StartNowImage from "../../components/Icons/StartNowImage";
// import { useI18n } from 'vue-i18n'
import NappsBlue from "../../components/Icons/NappsBlue.vue";

export default defineComponent({
  components: {
    Spinner,
    StartNowImage,
    NappsBlue,
  },
  setup() {
    // const { t } = useI18n({ useScope: 'global' })

    const store = useStore();
    const router = useRouter();
    const state = reactive({
      email: "",
      password: "",
    });

    const showLoading = ref(false);
    const rules = {
      email: { required, email },
      password: { required },
    };

    const errorMessage = computed(() => store.getters["auth/loginErrorMsg"]);
    const loginInProgress = computed(
      () => store.getters["auth/loginInProgress"]
    );
    const isLoggedIn = computed(() => store.getters["auth/isLoggedIn"]);
    const isShopConfigured = computed(
      () => store.getters["auth/isShopConfigured"]
    );
    const shopID = computed(() => store.getters["auth/shopID"]);

    const dashboardRedirect = router.currentRoute.value.query.dashboard;

    watch(loginInProgress, (val) => {
      showLoading.value = loginInProgress.value;
    });

    watch([isLoggedIn, isShopConfigured], (newValues, oldValues) => {
      showLoading.value = false;
      console.error(newValues, dashboardRedirect);
      if (isLoggedIn.value) {
        if (isShopConfigured.value != null) {
          // Want to redirect to dashboar embed
          if (dashboardRedirect) {
            // console.error('Dashboard embed')
            router.push("/embed/dashboard");
          } else if (isShopConfigured.value) {
            // console.error('Open dashboard')
            store.dispatch("auth/openDashboard", {
              href: `https://${shopID.value}.napps-solutions.com`,
            });
          } else {
            // console.error('Go To login')
            router.push("/");
          }
        } else {
          store.dispatch("auth/getShop");
        }
      }
    });

    const v$ = useVuelidate(rules, state, { $lazy: true });

    const login = async () => {
      v$.value.$touch();
      const isFormCorrect = await v$.value.$validate();
      if (isFormCorrect) {
        showLoading.value = true;
        store.dispatch(
            "auth/login",
            state,
            "Incorrect credentials. Try again."
          );
      }
    };

    return {
      state,
      errorMessage,
      v$,
      login,
      isLoggedIn,
      showLoading,
    };
  },
});
</script>

<style scoped>
/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

input {
  padding: 10px 20px;
  --tw-ring-color: white !important;
}
input:focus-visible {
  outline: none !important;
}
input:focus {
  outline: none !important;
}

.first-section {
  background-color: #263238;
  opacity: 1;
  align-items: center;
  display: flex;
  justify-content: center;
}
.second-section {
  background-color: #ffffff;
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 100px;
}
.main-container {
  display: flex;
  flex-flow: row;
}
.topBar {
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 60%;
  padding: 20px 0;
}
.napps-logo {
  width: 130px;
  height: auto;
}
.redirect-section {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.redirect-section > span {
  font-family: GothamBook;
  color: #263238;
  font-size: 18px;
  margin-right: 30px;
}
.redirect-section > a {
  border-radius: 5px;
  color: #263238;
  font-family: GothamBook;
  padding: 7px 20px;
  font-size: 16px;
  line-height: 24px;
  border-width: 1px;
  border-style: solid;
  border-color: #263238;
}
.redirect-section > a:hover {
  border-radius: 5px;
  color: white;
  background-color: #263238;
}
.title-login {
  text-align: left;
  font-size: 40px;
  font-weight: 700;
  letter-spacing: -0.00658em;
  line-height: 1.15789;
  font-family: GothamBold, "sans-serif";
  color: #284452;
  margin-bottom: 20px;
}
.link-text {
  font-size: 16px;
  text-align: left;
  font-weight: 500;
  font-family: GothamBook;
  color: #767676;
  margin-top: 15px;
  margin-bottom: 40px;
}
.fqZrOB {
  min-height: 80px;
  background: white;
  width: 90%;
  margin-bottom: 20px;
}
.ysiiV {
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  line-height: 26px;
  font-family: GothamBook;
  font-style: normal;
  font-weight: 400;
  margin-top: 5px;
  margin-bottom: 3px;
  color: rgb(0, 0, 0);
  text-align: left;
}
.jAWKuj {
  -webkit-font-smoothing: antialiased;
  line-height: 26px;
  color: rgb(0, 0, 0);
  font-family: GothamBook;
  font-style: normal;
  margin-bottom: 5px;
  margin-top: -3px;
  font-size: 15px;
  font-weight: 300;
}
.liAdpv {
  position: relative;
  margin-right: 50px;
}
.jsBTvB {
  position: relative;
  width: 100%;
}
.jsBTvB {
  border-radius: 3px;
  border: 1px solid rgb(186, 196, 224);
  background: rgb(252, 254, 255);
  letter-spacing: 0.25px;
  color: rgb(0, 0, 0);
  width: 100%;
  font-family: GothamBook, -apple-system, BlinkMacSystemFont, Helvetica, Arial,
    sans-serif;
  font-size: 14px;
  font-weight: 400;
  transition: border 200ms ease 0s;
  text-align: left;
}
.input-style {
  width: 100%;
}

.text-error {
  color: red;
  margin-top: 5px;
  font-family: GothamBook;
}

.main-button {
  background-color: #284452;
}

.image-asset {
  width: 50%;
}

button:hover {
  opacity: 0.8;
}

@media screen and (max-width: 1650px) {
  .main-container {
    grid-template-rows: 20px 1fr 20px !important;
  }
  .second-section {
    padding: 0 !important;
  }
  .second-section > div {
    padding: 2rem !important;
  }
  .topBar {
    width: 65%;
  }
}

@media screen and (max-width: 1200px) {
  .login-section {
    flex-direction: column;
  }
    .logo-top {
    padding-top: 10px;
    align-items: center !important;
    justify-content: center !important;
  }
  .main-container {
    grid-template-rows: 1fr 1fr !important;
    grid-auto-columns: 1fr;
  }
  .first-section {
    flex: 1;
    width: 100vw;
  }
    .logo-top {
    margin-bottom: 30px;
  }
  .second-section {
    flex: 2;
    height: 100%;
    margin: 0px !important;
    width: 100%;
    padding-top: 0px !important;
  }
  .main-button {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 1025px) {
  .main-container {
    background-color: white;
  }
  .content {
    padding: 0px !important;
    width: 70vh;
    background-color: white;
  }
  .second-section {
    display: flex;
    width: 100vw;
    align-items: center;
    margin-top: 200px;
    margin-left: 20px;
  }
  .first-section {
    display: none;
  }
}

@media screen and (max-width: 1028px) {
  .liAdpv {
    margin-left: 0px;
    margin-right: 0px;
  }
  .first-section {
    grid-row: 1;
  }
  .second-section {
    margin-left: 0px;
    margin-top: 0px;
    padding-top: 50px;
    margin-right: 0px;
    width: 100vw;
    grid-row: 2;
  }
  .login-section {
    max-width: 100%;
    margin-right: 0px;
  }
  .second-section > div {
    min-width: 100vw;
    max-width: 100vw;
    padding: 10px !important;
  }
  .liAdpv {
    margin-right: 0px;
  }
  .fqZrOB {
    width: 100%;
    margin-right: 0px;
  }
  .image-asset {
    max-width: 100vw;
  }
}

@media screen and (max-width: 600px) {
  .input-section {
    padding-top: 0px !important;
  }
  .topBar {
    flex-direction: column;
    width: 100%;
  }
  .redirect-section {
    margin-top: 20px;
  }
  .redirect-section > span {
    font-size: 16px;
  }
  .redirect-section {
    justify-content: unset;
  }
  .label-account {
    flex: 2;
  }
  .second-section {
    padding: 10px !important;
  }
}
</style>
