<template>
    <div class="main h-screen w-full" >
        <router-link to="/login"><logo-white style="height: auto; margin-bottom: 50px;" v-on:click="goLogin" class="w-52 logo-napps" /></router-link>
        <div class="main-container" >
            <div class="forgot-container">
                <h1 class="title">{{ $t("forget.message_forgot_title") }}</h1>
                <h1 class="sub-title">{{ $t("forget.message_forgot_description") }}</h1>
                <div class="input-box">
                    <p class="input p">Email</p>
                    <input 
                        :style="v$.email.$error ? 'border-color: red' : ''"
                        class="input-style"
                        v-model="v$.email.$model"
                        type="email" 
                        placeholder="Email Address"
                        @keypress.enter="sendRequest()"
                    >
                </div>
                 <p v-if="v$.email.$error && v$.email.$errors[0].$validator == 'required'" class="text-left text-sm text-error">{{ $t("startnow.error_required_email") }}</p>
                 <p v-if="v$.email.$error && v$.email.$errors[0].$validator == 'email'" class="text-left text-sm text-error">{{ $t("startnow.error_invalid_email") }}</p>
                <p v-if="errorMessage" class="text-left text-sm text-red-500">
                    {{ errorMessage }}
                </p>
                <p v-if="sucessMessage" class="text-left text-sm sucess-message">
                    Se inseriu o email correto irá receber um email de recuperação.
                </p>
                <button 
                    v-on:click="sendRequest"
                    style="width: 150px; height: auto; margin-top: 30px;" 
                    class="main-button block shadow bg-black font-semibold focus:shadow-outline focus:outline-none text-white text-md py-2 px-4 rounded">
                    {{ $t("forget.message_forgot_submit") }}
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import { reactive, defineComponent, ref, watch, computed } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { email, required } from "@vuelidate/validators";
import { useI18n  } from 'vue-i18n'
import { useStore } from "@/store";
import { useRouter } from "vue-router";
import axios from 'axios'
import Logo from "@/components/Icons/Logo.vue";
import LogoWhite from "@/components/Icons/LogoWhite.vue";


export default defineComponent({
    components: { 
        Logo,
        LogoWhite
     },
    setup() {
        const store = useStore();
        const router = useRouter();
        const { t } = useI18n();

        const state = reactive({
            email: "",
        });
        const errorMessage = ref("");
        const sucessMessage = ref("");

        const rules = {
            email: { required, email },
        };

        const v$ = useVuelidate(rules, state, { $lazy: true });
        const isLoggedIn = computed(() => store.getters["auth/isLoggedIn"]);

        watch(isLoggedIn, (newValue, oldValue) => {
            if (newValue) {
                router.push("/");
            }
        });

        const sendRequest = async () => {
            v$.value.$touch();
            const isFormCorrect = await v$.value.$validate();
            if(!isFormCorrect)
                return

            axios.post(window.nappmaster + "/forgot", state)
                .then(function (response) {
                    sucessMessage.value = t("auth.success")
                    errorMessage.value = "";
                })
                .catch(function (error) {
                    sucessMessage.value = "";
                    errorMessage.value = t("auth.forgot_email_does_not_exists")
                });
        };

        return {
            state,
            v$,
            sendRequest,
            errorMessage,
            sucessMessage
        };
  }
})
</script>

<style scoped>

    .text-error {
        color: red;
        margin-top: 5px;
        font-family: GothamBook;
    }

    input:focus {
        outline: none;
    }
    .main {
        background-color: #263238;
        display: flex;
         flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .main-container {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .forgot-container {
        background: white;
        max-width: 500px;
        padding: 50px 50px 40px;
        border-radius: 5px;
        overflow: hidden;
        margin: 0px auto;
        position: relative;
        box-shadow: rgb(0 0 0 / 5%) 0px 2px 10px 0px, rgb(0 0 0 / 2%) 0px 2px 20px 0px, rgb(0 0 0 / 2%) 0px 2px 30px 0px;
        background: white;
        width: 100%;
    }
    .title {
        color: #284452;
        text-align: center;
        font-size: 25px;
        font-family: GothamBold;
        font-weight: 500;
        font-style: normal;
        margin-top: -20px;
    }
    .sub-title {
        color: rgb(161, 168, 185);
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 20px;
        text-align: left;
        margin-top: 20px;
    }
    .input-box {
        position: relative;
        width: 100%;
    }
    .input {
        font-family: GothamBook;
        margin-top: 8px;
        -webkit-font-smoothing: antialiased;
        margin-bottom: 7px;
        font-weight: 400;
        font-style: normal;
        font-size: 14px;
        line-height: 1.5;
        display: flex;
        flex-flow: row nowrap;
        -webkit-box-align: center;
        align-items: center;
        letter-spacing: normal;
        color: rgb(0, 0, 0);
    }
    .input-style {
        border-radius: 3px;
        border: 1px solid rgb(186, 196, 224);
        background: rgb(252, 254, 255);
        padding: 10px 20px;
        letter-spacing: 0.25px;
        color: rgb(0, 0, 0);
        width: 100%;
        font-family: SofiaPro, -apple-system, BlinkMacSystemFont, Helvetica, Arial, sans-serif;
        font-size: 14px;
        font-weight: 400;
        transition: all 0.2s ease-in 0s;
    }
    input:focus {
        border: 1px solid #284452;
    }
    .main-button {
        background-color: #284452;
    }
    .sucess-message {
        font-family: GothamBook;
        color: rgb(161, 168, 185);
        margin-top: 5px;
    }

    button:hover {
        opacity: 0.8;
    }

    @media only screen and (max-width: 1024px) {
        .main-container {
            margin: 20px;
        }
        .logo-napps {
            width: 150px;
            margin-bottom: 20px !important;
        }
        .forgot-container {
            padding: 40px 30px;
        }
    }
</style>