<template>
    <div class="main h-screen w-full" >
        <div class="main-container" >
            <div class="forgot-container">
                <h1 class="title">{{ t("auth.reset.title") }}</h1>
                <h1 class="sub-title">{{ t("auth.reset.desc") }}</h1>
                <div class="input-box">
                    <p class="input p">Nova password</p>
                    <input 
                        :style="v$.password.$error ? 'border-color: red' : ''"
                        class="input-style"
                        v-model="v$.password.$model"
                        type="password" 
                        placeholder="Password nova"
                    >
                </div>
                <p v-if="v$.password.$error" class="text-left text-red-500 text-sm text-error">
                    {{ v$.password.$errors[0].$message }}
                </p>
                <div class="input-box">
                    <p class="input p">Confirma password</p>
                    <input 
                        class="input-style"
                        v-model="state.confirmation"
                        type="password" 
                        placeholder="Confirmação password nova"
                    >
                </div>
                <p v-if="errorMessage" class="text-left text-sm text-red-500">
                    {{ errorMessage }}
                </p>
                <p v-if="sucessMessage" class="text-left text-sm">
                    {{ sucessMessage }}
                </p>
                <button 
                    v-on:click="sendRequest"
                    style="width: 150px; height: auto; margin-top: 30px;" 
                    class="main-button block shadow bg-black font-semibold focus:shadow-outline focus:outline-none text-white text-md py-2 px-4 rounded">
                    Submit
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import { reactive, defineComponent, ref, computed, watch } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";
import { useI18n  } from 'vue-i18n'
import { useRouter } from "vue-router";
import { useStore } from "@/store";
import axios from 'axios'

export default defineComponent({
    setup() {
        const router = useRouter();
        const store = useStore();
        const token = router.currentRoute.value.query.token;
        const { t } = useI18n();
        const state = reactive({
            password: "",
            confirmation : ""
        });
        const errorMessage = ref("");
        const sucessMessage = ref("");
        const rules = {
            password: { 
                required, minLength: minLength(6)
            }
        };

        const isLoggedIn = computed(() => store.getters["auth/isLoggedIn"]);
        watch(isLoggedIn, (newValue, oldValue) => {
            if (newValue) {
                router.push("/");
            }
        });

        const v$ = useVuelidate(rules, state, { $lazy: true });
        const sendRequest = async () => {
            v$.value.$touch();
            const isFormCorrect = await v$.value.$validate();
            if(!isFormCorrect)
                return

            if(isFormCorrect && state.password !== state.confirmation) {
                errorMessage.value = t("auth.reset.passwords_mismatch")
                return;
            }

            axios.post(window.nappmaster + "/reset", state, {
                    headers: {
                        Authorization: token
                    }
                })
                .then(function (response) {
                    router.push("/login")
                    sucessMessage.value = t("auth.reset.success")
                    errorMessage.value = "";
                })
                .catch(function (error) {
                    sucessMessage.value = "";
                    errorMessage.value = t("error")
                });
        };

        return {
            state,
            v$,
            t,
            sendRequest,
            errorMessage,
            sucessMessage
        };
  }
})
</script>

<style scoped>

    input:focus {
        outline: none;
    }
    .main {
        background-color: #B0CBD8;
    }
    .main-container {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .forgot-container {
        background: white;
        max-width: 500px;
        padding: 50px 50px 40px;
        border-radius: 5px;
        overflow: hidden;
        margin: 0px auto;
        position: relative;
        box-shadow: rgb(0 0 0 / 5%) 0px 2px 10px 0px, rgb(0 0 0 / 2%) 0px 2px 20px 0px, rgb(0 0 0 / 2%) 0px 2px 30px 0px;
        background: white;
        width: 100%;
    }
    .title {
        color: #284452;
        text-align: center;
        font-size: 25px;
        font-family: GothamBold;
        font-weight: 500;
        font-style: normal;
        margin-top: -20px;
    }
    .sub-title {
        color: rgb(161, 168, 185);
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 20px;
        text-align: left;
        margin-top: 20px;
    }
    .input-box {
        position: relative;
        width: 100%;
    }
    .input {
        font-family: GothamBook;
        margin-top: 8px;
        -webkit-font-smoothing: antialiased;
        margin-bottom: 7px;
        font-weight: 400;
        font-style: normal;
        font-size: 14px;
        line-height: 1.5;
        display: flex;
        flex-flow: row nowrap;
        -webkit-box-align: center;
        align-items: center;
        letter-spacing: normal;
        color: rgb(0, 0, 0);
    }
    .input-style {
        border-radius: 3px;
        border: 1px solid rgb(186, 196, 224);
        background: rgb(252, 254, 255);
        padding: 10px 20px;
        letter-spacing: 0.25px;
        color: rgb(0, 0, 0);
        width: 100%;
        font-family: SofiaPro, -apple-system, BlinkMacSystemFont, Helvetica, Arial, sans-serif;
        font-size: 14px;
        font-weight: 400;
        transition: all 0.2s ease-in 0s;
    }
    input:focus {
        border: 1px solid #284452;
    }
    .main-button {
        background-color: #284452;
    }
</style>