<template>
  <svg
    width="13"
    height="16"
    viewBox="0 0 13 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      v-if="!active"
      d="M2.89824 15.6524L11.2399 9.09335C11.2787 9.06286 11.3202 9.03539 11.3623 9.00965C12.2908 8.44157 12.2908 7.55841 11.3622 6.99014C11.3202 6.96441 11.2787 6.93696 11.2399 6.9065L2.89944 0.348045C2.7157 0.218976 2.38761 0.0799427 2.16481 0.0516777C1.44281 -0.0391617 0 -0.111336 0 0.763916L0 15.2357C0 16.1109 1.44198 16.0392 2.16378 15.9485C2.38662 15.9205 2.7145 15.7812 2.89824 15.6524Z"
      fill="#708C9A"
    />
    <path
      v-else
      d="M2.89824 15.6524L11.2399 9.09335C11.2787 9.06286 11.3202 9.03539 11.3623 9.00965C12.2908 8.44157 12.2908 7.55841 11.3622 6.99014C11.3202 6.96441 11.2787 6.93696 11.2399 6.9065L2.89944 0.348045C2.7157 0.218976 2.38761 0.0799427 2.16481 0.0516777C1.44281 -0.0391617 0 -0.111336 0 0.763916L0 15.2357C0 16.1109 1.44198 16.0392 2.16378 15.9485C2.38662 15.9205 2.7145 15.7812 2.89824 15.6524Z"
      fill="#455A64"
    />
  </svg>
</template>
<script>
export default {
  name: "ArrowSupport",
  data() {
    return {};
  },
  props: {
    active : false,
    fill: {
      default: "--color-primary",
    },
  },
};
</script>