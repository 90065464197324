<template>
  <div
    style="margin: 0px !important; background: none; position: absolute"
    class="h-16"
  >
    <nav
      id="nav-bar-background"
      ref="navBarBG"
      class="h-16 fixed top-0 w-full z-20"
    >
      <div
        ref="navBar"
        id="nav-bar"
        class="mobile-navbar-expanded sm:px-12 h-16"
      >
        <div class="relative flex items-center justify-between h-16">
          <div
            class="absolute inset-y-0 left-0 flex items-center lg:hidden cross"
          >
            <!-- Mobile menu button-->
            <button
              aria-controls="mobile-menu"
              aria-expanded="false"
              v-on:click="mobileBtnClick"
              ref="mobileBtn"
              class="
                inline-flex
                items-center
                justify-center
                rounded-md
                text-black
                focus:outline-none
                focus:ring-2
                focus:ring-inset
                focus:ring-white
                menu-btn
              "
              type="button"
            >
              <close-home
                class="close-icon"
                style="width: 50px; max-height: 35px"
              />
              <hamburger-icon
                :isWhite="true"
                class="menu-icon"
                style="width: 70px; max-height: 65px; color: white"
              />
            </button>
          </div>
          <div
            class="
              flex-1 flex
              items-center
              justify-center
              lg:items-stretch lg:justify-between
              box-nav
            "
          >
            <div class="flex-shrink-0 flex items-center first-section">
              <router-link to="/support">
                <napps-help />
              </router-link>
            </div>
            <div class="hidden lg:block lg:ml-6">
              <div style="height: 100%" class="flex space-x-4">
                <div class="input-wrapper">
                  <input
                    class="support-input"
                    placeholder="How can we help?"
                    name="search"
                    type="search"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Sub Nav Bar Support -->
      <div class="subNavbarOutside">
        <div class="sm:px-12 subNavbarInside">
          <div v-for="section in sections" :key="section.id">
            <router-link :to="'/support/' + section.id">
              <div :class="{ active: section.id == id }" class="sub-item">
                <span :class="{ active: section.id == id }" class="itemTitle">{{
                  section.title
                }}</span>
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <div :class="{ 'nav-fill': fillBG }" class="nav-bg nav-fill"></div>
    </nav>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import { gsap } from "gsap";
import { useStore } from "@/store";
import { useRoute } from "vue-router";
import axios from "axios";
import NappsHelp from "../Icons/NappsHelp.vue";
import LogoWhite from "../Icons/LogoWhite.vue";
import HamburgerIcon from "../Icons/HamburgerIcon.vue";
import CloseHome from "../Icons/CloseHome.vue";
import { integer, numeric } from "@vuelidate/validators";

export default defineComponent({
  data: function () {
    return {
      isOpen: false,
      tlx: undefined,
    };
  },
  components: {
    NappsHelp,
    LogoWhite,
    HamburgerIcon,
    CloseHome,
  },
  props: {
    fillBG: Boolean,
    id: Number,
  },
  computed: {
    isLoggedIn() {
      return useStore().getters["auth/isLoggedIn"];
    },
    getShop() {
      return useStore().getters["auth/shop"];
    },
    isMenuOpen() {
      return this.isOpen;
    },
    sections() {
      return useStore().getters["support/sections"];
    },
  },
  onDismout() {
    window.onscroll = null;
  },
  mounted() {
    const store = useStore();

    const path = this.$route.path;

    if (
      path === "/features" ||
      path === "/" ||
      path === "/pricing" ||
      path === "/support"
    ) {
      this.$refs.navBarBG.classList.remove("nav-bar-shadow");
    } else {
      this.$refs.navBarBG.classList.add("nav-bar-shadow");
      this.$refs.navBarBG.classList.add("nav-bar-force-white-background");
    }

    let width = window.innerWidth;
  },
  methods: {
    onDashboardClick: function (event) {
      useStore().dispatch("auth/openDashboard", {
        href: "https://" + this.getShop.id + ".napps-solutions.com",
      });
    },
    onLogout: function (event) {
      useStore().dispatch("auth/logout");
    },

    mobileBtnClick: function (event) {
      if (this.isOpen) {
        // this.$refs.mobileMenu.style.display = "none";

        this.isOpen = false;
        this.$refs.mobileBtn.classList.remove("is-active");

        if (this.tlx) {
          this.tlx.kill();
        }

        let tl = gsap.timeline();
        tl.to(".auth-btns-container", { opacity: "0", duration: 0.2 });
        tl.to(".fourth-option", { opacity: "0", duration: 0.1 });
        tl.to(".third-option", { opacity: "0", duration: 0.1 });
        tl.to(".second-option", { opacity: "0", duration: 0.1 });
        tl.to(".first-option", { opacity: "0", duration: 0.1 });
        tl.to(".close-icon", { display: "none", duration: 0.1 });
        tl.to(".menu-icon", { display: "block", duration: 0.1 });
        tl.to(".mobile-navbar-expanded", {
          height: "64px",
          duration: 0.2,
          ease: "sine.in",
          onComplete: () => {
            this.$refs.mobileMenu.style.display = "none";
          },
        });
        tl.to(".logo-dark", { opacity: "1", duration: 0.2 });
        tl.to("#chat_layout", { display: "block", duration: 0.2 });
        this.tlx = tl;
      } else {
        this.isOpen = true;
        this.$refs.mobileBtn.classList.add("is-active");
        this.$refs.mobileMenu.style.display = "flex";

        if (this.tlx) {
          this.tlx.kill();
        }

        let tl = gsap.timeline();
        tl.to(".logo-dark", { opacity: "0", duration: 0.2 });
        tl.to(".menu-icon", { display: "none", duration: 0.1 });
        tl.to("#nav-bar", { backgroundColor: "#263238", duration: 0.3 });
        tl.to(".mobile-navbar-expanded", {
          height: "100vh",
          duration: 0.2,
          ease: "slow(0.7, 0.7, false)",
        });
        tl.to(".close-icon", { display: "block", duration: 0.1 });
        tl.to(".first-option", { opacity: "1", delay: 0.1, duration: 0.2 });
        tl.to(".second-option", { opacity: "1", duration: 0.2 });
        tl.to(".third-option", { opacity: "1", duration: 0.2 });
        tl.to(".fourth-option", { opacity: "1", duration: 0.2 });
        tl.to(".auth-btns-container", { opacity: "1", duration: 0.2 });
        tl.to("#chat_layout", { display: "none", duration: 0.2 });
        this.tlx = tl;
      }
    },
  },
});
</script>

<style scoped>
.input-wrapper {
  min-width: 500px;
}

.input-wrapper:before {
  position: absolute;
  background: url(https://fiverr-res.cloudinary.com/npm-assets/@fiverr/support_page_perseus/apps/search.3852bf0.svg)
    50% no-repeat;
  width: 20px;
  height: 20px;
  z-index: 1;
  content: "";
  display: block;
  margin: 13px 0 0 16px;
}

input {
  --tw-ring-color: none !important;
}

[type='search'] { 
  outline: none;
  outline-offset: 0px;
}

.support-input {
  width: 100%;
  border: 1px solid white;
  color: #404145;
  padding: 10px 24px 10px 48px;
  border-radius: 12px;
  font-family: GothamBook;
}
.support-input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-family: GothamBook;
  opacity: 1; /* Firefox */
}

.support-input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-family: GothamBook;
}

.support-input::-ms-input-placeholder {
  /* Microsoft Edge */
  font-family: GothamBook;
}

.nav-bg {
  position: absolute;
  top: 0px;
  width: 100%;
  overflow: hidden;
  transition: padding-top 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
  background-color: #455a64;
}

.nav-fill {
  padding-top: 63px;
}

.button-nav-top {
  border-radius: 5px;
  color: white;
  font-family: GothamBook;
  padding: 7px 20px;
  font-size: 16px;
  line-height: 24px;
}

.button-nav-top:hover {
  opacity: 0.8;
}

.button-fill {
  background-color: white;
  border-width: 1px;
  border-style: solid;
  border-color: white;
  color: #101921;
}

.button-outline {
  border-width: 1px;
  border-style: solid;
  border-color: white;
  color: white;
}

.menu-icon > path {
  fill: white;
}

button:focus {
  outline: none !important;
  --tw-ring-inset: none !important;
}

.close-icon {
  fill: white;
  margin-right: 10px;
  display: none;
}

.close-icon:focus {
  outline: none !important;
}

.logo-white {
  display: none;
}
.first-section {
  width: 250px;
}

.third-section {
  width: 250px;
}

svg {
  max-width: 190px;
  max-height: 29px;
}

a {
  font-family: GothamBook;
  font-size: 16px;
  color: white;
}

.auth-btns-container {
  display: flex;
  flex-flow: row;
  background-color: white;
  padding-top: 30px;
  flex-shrink: 0;
  margin-top: auto;
  position: fixed;
  width: 100%;
  bottom: 0;
}

.mobile-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row !important;
  width: 100%;
}

.mobile-buttons > a {
  flex: 1;
}

.auth-btns-container > a {
  margin: 6px;
}

.button-auth {
  max-height: 38px;
  border: 1px solid white;
  color: white;
  padding-bottom: 2px;
  padding-left: 25px;
  padding-right: 25px;
  font-size: 16px;
  font-family: GothamMedium;
  border-radius: 5px;
  vertical-align: middle;
  line-height: 38px;
}

.button-auth-register {
  color: #ffffff;
  background-color: #101921;
}

.menu-btn {
  transform: scale(0.6);
  margin-top: 5px;
}

.first-option,
.second-option,
.third-option,
.fourth-option,
.auth-btns-container {
  opacity: §;
}

.auth-btns-container {
  display: flex;
  flex-direction: row;
}

#nav-bar {
}

.nav-bar-shadow {
  box-shadow: rgb(0 0 0 / 10%) 0px 2px 30px 0px;
}

.nav-bar-force-white-background {
  background-color: white !important;
}

.mobile-navbar-expanded {
  width: 101%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
  position: fixed;
  overflow: hidden;
  top: 0;
}

.blue-nav {
  background-color: rgb(43, 56, 78);
}

.mobile-navbar {
  position: fixed;
  top: 64px;
  background: transparent;
  height: 100vh;
  width: 100vw;
  z-index: 100000;
  flex-direction: column !important;
}

.nav-item {
  text-decoration: none;
  position: relative;
  text-transform: uppercase;
  font-family: GothamMedium;
}

.button-nav {
  max-height: 38px;
  border: 1px solid #101921;
  color: #101921;
  padding-bottom: 2px;
  padding-left: 25px;
  padding-right: 25px;
  font-size: 16px;
  font-family: GothamBook;
  border-radius: 5px;
  vertical-align: middle;
  line-height: 38px;
}

.subNavbarOutside {
  background-color: #455a64;
  padding: 40px 0 0 0;
  width: 100%;
  position: absolute;
  top: 62px;
}

.subNavbarInside {
  display: flex;
  flex-direction: row;
  gap: 70px;
}

.sub-item {
  padding: 5px 0;
  cursor: pointer;
}

.itemTitle {
  color: white;
  font-family: GothamMedium;
  font-size: 18px;
  padding: 10px 0px;
}

.sub-item.active {
  border-bottom: 3px solid #fbbc04;
}

.itemTitle.active {
  color: #fbbc04;
}

@media only screen and (min-width: 1024px) {
  .logo-mobile {
    display: none;
  }
}

@media only screen and (max-width: 1024px) {
  .first-section {
    justify-content: center;
    z-index: 10000;
  }
  .logo-web {
    display: none;
  }
  .text-base {
    width: 100%;
    max-width: 320px;
    font-size: 30px;
    line-height: 38px;
    margin: 0px 0px 40px !important;
    color: white;
    font-family: "GothamBook";
  }
  .auth-btns-container > div {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
  .auth-btns-container > div > a:first-child {
    width: 100%;
    max-width: 200px;
    font-size: 20px;
    line-height: 0px;
    margin: 0px 0px 40px !important;
    padding: 25px;
    background-color: rgb(38, 50, 56);
    color: white;
    font-family: "GothamBook";
    text-align: center;
  }
  .auth-btns-container > div > a:first-child:hover {
    opacity: 0.8;
  }
  .auth-btns-container > div > a:last-child {
    width: 100%;
    max-width: 200px;
    font-size: 20px;
    line-height: 0px;
    margin: 0px 0px 40px !important;
    color: rgb(38, 50, 56);
    font-family: "GothamBook";
    text-align: center;
    padding: 25px;
    border: 1px solid rgb(38, 50, 56);
    margin-left: 0.75rem;
  }
  .cross {
    width: 100%;
    justify-content: flex-end;
  }
  .box-nav {
    align-items: flex-start;
    justify-content: flex-start;
  }
  .first-section {
    padding-left: 30px;
    justify-content: flex-start;
  }
  .first-section > a > svg {
    margin-top: 7px;
  }
  #nav-bar {
    padding: 0px;
  }
  .subNavbarInside {
    gap: 30px;
    flex-wrap: wrap;
  }
  .subNavbarOutside {
    padding: 20px 0 0 0;
  }
  .sub-item {
    border-bottom: 3px solid #455a64;
  }
  .menu-btn {
    display: none;
  }
  .main-page {
    margin-top: 13rem;
  }
}
</style>
