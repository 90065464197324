<template>
<svg width="207" height="120" viewBox="0 0 207 120" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="3.00098" y="3" width="167.361" height="113.472" rx="17" stroke="#455A64" stroke-width="6"/>
<line y1="30.291" x2="173.362" y2="30.291" stroke="#455A64" stroke-width="5"/>
<circle cx="18.8111" cy="17.2857" r="3.05034" fill="#455A64"/>
<circle cx="28.979" cy="17.2857" r="3.05034" fill="#455A64"/>
<circle cx="39.147" cy="17.2857" r="3.05034" fill="#455A64"/>
<rect x="183.498" y="1.24121" width="27.8722" height="105.512" rx="13.9361" transform="rotate(37.15 183.498 1.24121)" fill="white"/>
<path d="M195.096 9.01992C188.533 4.04642 179.147 5.33982 174.173 11.9031L132.48 66.9237C130.381 69.6934 129.12 72.9877 128.835 76.4502L127.818 88.7659C127.635 90.9829 128.585 93.1085 130.358 94.4518C132.131 95.7954 134.434 96.1342 136.518 95.3584L148.1 91.0489C151.356 89.8372 154.187 87.7328 156.286 84.9634L197.979 29.9428C202.953 23.3797 201.66 13.9934 195.096 9.01992ZM179.18 15.6971C182.062 11.8947 187.5 11.1452 191.302 14.0269C195.105 16.9082 195.854 22.3462 192.973 26.1487L158.025 72.2674L144.232 61.8158L179.18 15.6971ZM151.279 81.1695C149.902 82.9861 148.045 84.3665 145.909 85.1613L134.328 89.4708C134.29 89.4849 134.233 89.506 134.152 89.4448C134.071 89.3835 134.076 89.3225 134.079 89.2826L135.096 76.9668C135.283 74.6956 136.11 72.5346 137.487 70.7178L140.438 66.8229L154.231 77.2745L151.279 81.1695Z" fill="#455A64"/>
</svg>

</template>
<script>
export default {
    name: 'Design',
    data() {
        return {}
    },
    props: {
        fill: {
            default: '--color-primary'
        }
    },
}
</script>