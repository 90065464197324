<template>
  <!-- <nav-bar /> -->
  <div class="h-screen flex">
    <nav-support :id="idParam" :fillBG="true" />
    <div v-if="subSections && sections" class="w-full mt-36 main-page flex">
      <div class="main-support flex flex-1 gap-6">
        <div class="subSectionSidebar">
          <div class="flex gap-6 flex-col" v-if="subSections.length > 1">
            <div
              v-for="subSection in subSections"
              :key="subSection.id"
              class="subSectionItem"
            >
              <div v-on:click="clickedSubSection(subSection.id)" :class="{'opened': subSectionId == subSection.id}" class="subSectionItemInside">
                <arrow-support :active="subSectionId == subSection.id" :class="{'rotateArrow': subSectionId == subSection.id}" class="arrowSubSection" />
                <div class="subSectionTitle">{{ subSection.title }}</div>
              </div>
              <div
                class="subSectionArticle"
                :class="{'hidden': subSectionId != subSection.id, 'active': articleSelected == article.id}"
                v-for="article in getSubSectionArticles(subSection.id)"
                v-on:click="clickedArticle(article.id)"
                :key="article.id"
              >
                <div >
                    {{ article.title }}
                </div>
              </div>
            </div>
          </div>
          <div v-else-if="subSections.length == 1">
            <div
              v-for="article in getSubSectionArticles(subSections[0].id)"
              v-on:click="clickedArticle(article.id)"
              :key="article.id"
              :class="{'active': articleSelected == article.id}"
              class="articleItem noSubSections"
            >
              {{ article.title }}
            </div>
          </div>
        </div>
        <div class="article-content" v-if="articleSelected != -1" >
            <h1 class="articleTitle" >{{getArticleById[0].title}}</h1>
            <div v-html="getArticleById[0].data" >
            </div>
        </div>
      </div>
    </div>
  </div>
  <footer :minify="true" />
</template>
<script>
import { defineComponent } from "vue";
import NavSupport from "@/components/Navbar/SupportItem.vue";
import ArrowSupport from "@/components/Icons/ArrowSupport.vue";
import { useRoute } from "vue-router";
import { useStore } from "@/store";

export default defineComponent({
  components: {
    NavSupport,
    ArrowSupport,
  },
  data: function () {
    return {
      id: null,
      subSectionId: -1,
      articleSelected: -1,
      articleSelectedIndex: -1,
    };
  },
  unmounted() {
    window.scrollTo(0, 0);
  },
  mounted() {
    const store = useStore();
    this.id = useRoute().params.id;

    if (store.getters["support/checkEmpty"]) {
      store.dispatch("support/getSections");
    }

    if (store.getters["support/checkSubSectionsEmpty"]) {
      store.dispatch("support/getAllSubSections");
    }

    store.dispatch("support/getAllArticles");
  },
  methods: {
    clickedArticle: function (id) {
      this.articleSelected = id;
    },
    getSubSectionArticles: function (id) {
      let articles = useStore().getters["support/articles"];
      let articlesFiltered = articles.filter((e) => e.subSectionId == id);
      if(this.articleSelected == -1 && articlesFiltered.length > 0){
        this.subSectionId = parseInt(articlesFiltered[0].subSectionId)
        this.articleSelected = parseInt(articlesFiltered[0].id);
      }
      return articlesFiltered;
    },
    clickedSubSection: function (id) {
        if(this.subSectionId == id){
            this.subSectionId = -1
        }else{
            this.subSectionId = id
        }
    },
  },
  watch: {
    '$route': function(to, from) {
      this.articleSelected = -1;
      this.articleSelectedIndex = -1;
      this.subSectionId = -1;
    },

  },
  computed: {
    idParam() {
      this.id = useRoute().params.id;
      return useRoute().params.id;
    },
    articles() {
      let articles = useStore().getters["support/articles"];
      return articles.filter((e) => e.subSectionId == this.subSectionId);
    },
    getArticleById(){
        let articles = useStore().getters["support/articles"];
        return articles.filter((e) => e.id == this.articleSelected);
    },
    sections() {
      return useStore().getters["support/categoryById"](this.id);
    },
    subSections() {
      let subSections = useStore().getters["support/subSections"];
      let sectionId = this.id;
      return subSections.filter((e) => e.sectionId == sectionId);
    },
  },
});
</script>

<style scoped>
.subSectionSidebar {
  background-color: #fafafa;
  width: 300px;
  position: static;
  top: 0;
  left: 0;
  overflow: hidden;
  padding-top: 40px;
}

.noSubSections {
  padding-left: 40px !important;
  padding-top: 10px !important;
  padding-bottom: 15px !important;
}

.subSectionItem {
    padding: 0 20px;
}

.subSectionItemInside {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.subSectionTitle {
  color: #708c9a;
  font-family: GothamMedium;
  font-size: 20px;
  margin-left: 10px;
}

.arrowSubSection {
  width: 10px;
  height: auto;
}

.subSectionArticle {
    margin-left: 40px;
    font-size: 18px;
    font-family: GothamMedium;
    color: #979494;
    cursor: pointer;
}

.articleItem {
  color: #708c9a;
  font-family: GothamMedium;
  font-size: 20px;
  padding: 0 10px;
  cursor: pointer;
}

.active {
    color: #455A64;
}

.rotateArrow {
    transform: rotate(90deg);
}

.opened {
    padding-bottom: 20px;
}

.opened > .subSectionTitle {
    color: #455A64;
}

.opened > .arrowSubSection {
    color: #455A64;
}

.articleTitle {
    font-family: GothamMedium;
    font-size: 30px;
    color: #979494;
    margin-bottom: 10px;
}

.article-content {
    padding: 50px;
}
</style>