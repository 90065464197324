<template>
  <div>
    <div class="flex w-full h-screen flex-row login-section">
      <div class="first-section grid flex-1">
        <div class="image-box h-full">
          <start-now-image />
        </div>
      </div>
      <div class="second-section flex-2 p-52">
        <div class="topBar">
          <div class="flex logo-top">
            <router-link to="/">
              <napps-blue class="napps-logo" />
            </router-link>
          </div>
          <div class="redirect-section">
            <span class="label-account" >{{ $t("startnow.already") }}</span>
            <router-link  class="button" to="/login">{{$t("navbar.login")}}</router-link>
          </div>
        </div>
        <div class="flex flex-col w-full">
          <h2 class="title-login">
            {{ $t("startnow.title") }}
          </h2>
        </div>
        <div style="padding-top: 20px" class="flex flex-row input-section">
          <div class="fqZrOB">
            <p style="margin-bottom: 3px; color: #000000" class="ysiiV">
              {{ $t("startnow.firstName") }}
            </p>
            <div class="liAdpv">
              <div
                class="jsBTvB"
                :style="v$.firstName.$error ? 'border-color: red' : ''"
              >
                <input
                  class="input-style"
                  v-model="v$.firstName.$model"
                  name="FirstName"
                  :placeholder="
                    $t('startnow.placeholder_firstName')
                  "
                  style="font-size: 18px"
                />
              </div>
            </div>
            <p v-if="v$.firstName.$error" class="text-left text-sm text-error">
              {{ $t("startnow.error_required_firstname") }}
            </p>
          </div>
          <div class="fqZrOB">
            <p style="margin-bottom: 3px; color: #000000" class="ysiiV">
              {{ $t("startnow.lastName") }}
            </p>
            <p
              style="margin-top: -3px; font-size: 15px; font-weight: 300"
              class="jAWKuj"
            ></p>
            <div class="liAdpv">
              <div
                class="jsBTvB"
                :style="v$.lastName.$error ? 'border-color: red' : ''"
              >
                <input
                  class="input-style"
                  v-model="v$.lastName.$model"
                  name="LastName"
                  :placeholder="
                    $t('startnow.placeholder_lastName')
                  "
                  style="font-size: 18px"
                />
              </div>
            </div>
            <p v-if="v$.lastName.$error" class="text-left text-sm text-error">
              {{ $t("startnow.error_required_lastname") }}
            </p>
          </div>
        </div>
        <div style="padding-top: 20px" class="flex flex-row input-section">
          <div class="fqZrOB">
            <p style="margin-bottom: 3px; color: #000000" class="ysiiV">
              {{ $t("startnow.email") }}
            </p>
            <p
              style="margin-top: -3px; font-size: 15px; font-weight: 300"
              class="jAWKuj"
            ></p>
            <div class="liAdpv">
              <div
                class="jsBTvB"
                :style="v$.email.$error ? 'border-color: red' : ''"
              >
                <input
                  class="input-style"
                  v-model="v$.email.$model"
                  name="email"
                  type="email"
                  autocomplete="true"
                  :placeholder="
                    $t('startnow.placeholder_email')
                  "
                  style="font-size: 18px"
                />
              </div>
            </div>
            <p
              v-if="
                v$.email.$error && v$.email.$errors[0].$validator == 'required'
              "
              class="text-left text-sm text-error"
            >
              {{ $t("startnow.error_required_email") }}
            </p>
            <p
              v-if="
                v$.email.$error && v$.email.$errors[0].$validator == 'email'
              "
              class="text-left text-sm text-error"
            >
              {{ $t("startnow.error_invalid_email") }}
            </p>
          </div>
          <div class="fqZrOB">
            <p style="margin-bottom: 3px; color: #000000" class="ysiiV">
              {{ $t("startnow.phone") }}
            </p>
            <p
              style="margin-top: -3px; font-size: 15px; font-weight: 300"
              class="jAWKuj"
            ></p>
            <div class="liAdpv">
              <div
                class="jsBTvB"
                :style="v$.phoneNumber.$error ? 'border-color: red' : ''"
              >
                <input
                  class="input-style"
                  v-model="v$.phoneNumber.$model"
                  name="phoneNumberNumber"
                  :placeholder="
                    $t('startnow.placeholder_phone')
                  "
                  style="font-size: 18px"
                />
              </div>
            </div>
            <p
              v-if="v$.phoneNumber.$error"
              class="text-left text-sm text-error"
            >
              {{ $t("startnow.error_required_phone") }}
            </p>
          </div>
        </div>
        <div style="padding-top: 20px" class="flex flex-row input-section">
          <div class="fqZrOB">
            <p style="margin-bottom: 3px; color: #000000" class="ysiiV">
              Password
            </p>
            <p
              style="margin-top: -3px; font-size: 15px; font-weight: 300"
              class="jAWKuj"
            ></p>
            <div class="liAdpv">
              <div
                class="jsBTvB"
                :style="v$.password.$error ? 'border-color: red' : ''"
              >
                <input
                  class="input-style"
                  v-model="v$.password.$model"
                  name="Password"
                  type="password"
                  :placeholder="
                    $t('startnow.placeholder_password')
                  "
                  style="font-size: 18px"
                />
              </div>
            </div>
            <p v-if="v$.password.$error" class="text-left text-sm text-error">
              {{ $t("startnow.error_required_password") }}
            </p>
          </div>
          <div class="fqZrOB">
            <p style="margin-bottom: 3px; color: #000000" class="ysiiV">
              Website URL
            </p>
            <p
              style="margin-top: -3px; font-size: 15px; font-weight: 300"
              class="jAWKuj"
            ></p>
            <div class="liAdpv">
              <div
                class="jsBTvB"
                :style="v$.websiteUrl.$error ? 'border-color: red' : ''"
              >
                <input
                  class="input-style"
                  v-model="v$.websiteUrl.$model"
                  name="WebsiteUrl"
                  :placeholder="
                    $t('startnow.placeholder_website')
                  "
                  style="font-size: 18px"
                />
              </div>
            </div>
            <p v-if="v$.websiteUrl.$error" class="text-left text-sm text-error">
              {{ $t("startnow.error_required_website") }}
            </p>
          </div>
        </div>
        <p v-if="errorMessage" class="text-left text-sm text-error">
          {{ errorMessage }}
        </p>
        <div class="flex flex-row items-center justify-items-center mt-4 mb-2">
          <input :style="termsError ? 'border-color: red' : ''" v-on:click="clickTerms" id="c1" type="checkbox" />
          <label class="checkbox-label" for="c1"
            >{{$t("startnow.accept_terms")}}<router-link target="_blank" style="text-decoration: underline;" :to="'/terms'" >{{$t("startnow.terms_conditions")}}</router-link>{{$t("startnow.accept_terms_2")}}<router-link target="_blank" style="text-decoration: underline;" :to="'/privacy'" >{{$t("startnow.policy")}}</router-link></label
          >
        </div>
        <button
          v-on:click="register"
          style="width: 300px; height: auto"
          class="
            flex flex-row
            items-center
            justify-center
            main-button
            shadow
            bg-black
            font-semibold
            focus:shadow-outline focus:outline-none
            text-white text-xl
            py-3
            px-10
            rounded
            mt-1
          "
        >
          <spinner v-if="showLoading" />
          {{ $t("startnow.create") }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, defineComponent, ref, computed, watch, onBeforeUnmount } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { email, required, minLength } from '@vuelidate/validators'
import { useRouter } from 'vue-router'
import { useStore } from '@/store'
import { useI18n } from 'vue-i18n'
import axios from 'axios'
import StartNowImage from '../../components/Icons/StartNowImage'
import Spinner from '@/components/Icons/Spinner.vue'
import NappsBlue from '../../components/Icons/NappsBlue.vue'

export default defineComponent({
  components: {
    Spinner,
    StartNowImage,
    NappsBlue
  },
  data() {
    return {
      termsConditions: false
    }
  },
  setup() {
    
    const closeOnEscape = (e) => {
      if (e.key === "Enter") {
        register();
      }
    };

    document.addEventListener("keydown", closeOnEscape);

    onBeforeUnmount(() => {
      document.removeEventListener("keydown", closeOnEscape);
    });

    const { t } = useI18n({ useScope: 'global' })

    const store = useStore()
    const state = reactive({
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      password: '',
      websiteUrl: ''
    })

    const errorMessage = ref(null)
    const router = useRouter()
    const showLoading = ref(false)
    const termsConditions = ref(false)
    const termsError = ref(false)

    // Rules for validation
    const rules = {
      firstName: { required },
      lastName: { required },
      email: { required, email },
      phoneNumber: { required },
      password: { required, minLength: minLength(6) },
      websiteUrl: { required }
    }
    const v$ = useVuelidate(rules, state, { $lazy: true })
    const isLoggedIn = computed(() => store.getters['auth/isLoggedIn'])

    const redirectTo = router.currentRoute.value.query.redirectTo || null
    // Watch when we are logged in and redirect to home page
    watch(isLoggedIn, (newValue, oldValue) => {
      if (isLoggedIn.value) {
        if (redirectTo) {
          router.push('/shop/choose')
        } else {
          router.push('/')
        }
      }
    })

    const clickTerms = () => {
      termsError.value = false
      termsConditions.value = !termsConditions.value
    }

    const register = async () => {
      if(showLoading.value == true){
        return
      }
      v$.value.$touch()
      const isFormCorrect = await v$.value.$validate()
      if (!isFormCorrect) {
        return
      }
      if (!termsConditions.value) {
        termsError.value = true
        return
      }
      showLoading.value = true
      axios
        .post(window.nappmaster + '/client', state)
        .then(function (response) {
          showLoading.value = false
          store.dispatch('auth/login', {
            email: state.email,
            password: state.password
          })
        })
        .catch(function (error) {
          showLoading.value = false
          if (error.response) {
            const response = error.response.data
            switch (response.code) {
              case 0:
                errorMessage.value = t('startnow.error_server')
                break
              case 1:
                errorMessage.value = t('startnow.https_error')
                break
              case 2:
                errorMessage.value = t('startnow.already_account_error')
                break
              case 3:
                errorMessage.value = t('startnow.invalid_url')
                break
              case 5:
                errorMessage.value = t('startnow.domain_error')
                break
            }
          }
        })
    }

    return { state, v$, register, errorMessage, redirectTo, showLoading, termsError, clickTerms }
  }
})
</script>

<style scoped>
/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
input:focus {
  outline: none;
}

input:focus-visible {
  outline: none !important;
}
.flex-2 {
  flex: 2 1;
}
.image-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.first-section {
  background-color: #263238;
}
.second-section {
  padding-left: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.topBar {
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 60%;
  padding: 20px 0;
}
.napps-logo {
  width: 130px;
  height: auto;
}
.redirect-section {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.redirect-section > span {
  font-family: GothamBook;
  color: #263238;
  font-size: 18px;
  margin-right: 30px;
}
.redirect-section > a {
  border-radius: 5px;
  color: #263238;
  font-family: GothamBook;
  padding: 7px 20px;
  font-size: 16px;
  line-height: 24px;
  border-width: 1px;
  border-style: solid;
  border-color: #263238;
}
.redirect-section > a:hover {
  border-radius: 5px;
  color: white;
  background-color: #263238;
}
.title-login {
  text-align: left;
  font-size: 40px;
  font-weight: 700;
  letter-spacing: -0.00658em;
  line-height: 1.15789;
  font-family: GothamBold, "sans-serif";
  color: #284452;
}
.subtitle-login {
  text-align: left;
  font-size: 20px;
  font-family: GothamMedium, "sans-serif";
  color: #767676;
}
.fqZrOB {
  min-height: 80px;
  background: white;
  width: 48%;
  margin-bottom: 20px;
}
.ysiiV {
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  line-height: 26px;
  font-family: GothamBook;
  font-style: normal;
  font-weight: 400;
  margin-top: 5px;
  margin-bottom: 3px;
  color: rgb(0, 0, 0);
  text-align: left;
}
.jAWKuj {
  -webkit-font-smoothing: antialiased;
  line-height: 26px;
  color: rgb(0, 0, 0);
  font-family: GothamBook;
  font-style: normal;
  margin-bottom: 5px;
  margin-top: -3px;
  font-size: 15px;
  font-weight: 300;
}
.liAdpv {
  position: relative;
  margin-right: 50px;
}
.jsBTvB {
  position: relative;
  width: 100%;
}
.jsBTvB {
  border-radius: 3px;
  border: 1px solid rgb(186, 196, 224);
  background: rgb(252, 254, 255);
  padding: 10px 20px;
  letter-spacing: 0.25px;
  color: rgb(0, 0, 0);
  width: 100%;
  font-family: GothamBook;
  font-size: 14px;
  font-weight: 400;
  transition: border 200ms ease 0s;
  text-align: left;
}
.input-style {
  width: 100%;
}
.link-text {
  font-size: 18px;
  text-align: left;
  font-weight: 500;
  font-family: GothamMedium;
  color: #767676;
  margin-top: 40px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.text-error {
  color: red;
  margin-top: 5px;
  font-family: GothamBook;
}

.main-button {
  font-family: GothamMedium;
  background-color: #284452;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.checkbox-label {
  font-family: GothamBook;
  color: #767676;
  font-size: 16px !important;
  margin-left: 10px !important;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

button:hover {
  opacity: 0.8;
}

@media screen and (max-width: 1560px) {
  .second-section {
    padding: 2rem !important;
  }
}
@media screen and (max-width: 1200px) {
  .login-section {
    flex-direction: column;
  }
  .logo-top {
    padding-top: 10px;
    align-items: center !important;
    justify-content: center !important;
  }
  .first-section {
    display: none;
  }
  .topBar {
    width: 90%;
    position: relative;
    display: flex;
    flex-direction: column;
    padding-top: 0px;
    padding-bottom: 30px;
  }
  .logo-top {
    margin-bottom: 30px;
  }
  .title-login {
    font-size: 35px;
    margin-bottom: 20px;
  }
  .main-button {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100% !important;
  }
}
@media screen and (max-width: 800px) {
  .input-section {
    flex-direction: column;
  }
  .redirect-section {
    justify-content: unset;
  }
  .label-account {
    flex: 2;
  }
  .second-section {
    padding: 20px;
    flex: 1;
  }
  .first-section {
    grid-template-rows: auto 1fr minmax(auto, 400px);
  }

  .fqZrOB {
    width: 100%;
  }
  .login-section {
    display: block;
  }
}

@media screen and (max-width: 600px) {
  .login-section {
    display: block;
    max-width: 100%;
    margin-left: 0px;
    margin-top: 0px;
    max-height: 100%;
  }
  .input-section {
    padding-top: 0px !important;
  }
  .topBar {
    width: 100%;
  }
  .second-section {
    padding: 10px !important;
  }
    .redirect-section > span {
    font-size: 16px;
  }
  .liAdpv {
    margin-right: 0px !important;
  }
  .fqZrOB {
    margin-right: 0px;
  }
}

@supports (-webkit-appearance: none) or (-moz-appearance: none) {
  input[type="checkbox"],
  input[type="radio"] {
    --active: #263238;
    --active-inner: #fff;
    --focus: 0px rgba(39, 94, 254, 0.3);
    --border: #bbc1e1;
    --border-hover: #263238;
    --background: #fff;
    --disabled: #f6f8ff;
    --disabled-inner: #e1e6f9;
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 25px;
    outline: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0;
    cursor: pointer;
    border: 1px solid var(--bc, var(--border));
    background: var(--b, var(--background));
    transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
  }
  input[type="checkbox"]:after,
  input[type="radio"]:after {
    content: "";
    display: block;
    left: 0;
    top: 0;
    position: absolute;
    transition: transform var(--d-t, 0.3s) var(--d-t-e, ease),
      opacity var(--d-o, 0.2s);
  }
  input[type="checkbox"]:checked,
  input[type="radio"]:checked {
    --b: var(--active);
    --bc: var(--active);
    --d-o: 0.3s;
    --d-t: 0.6s;
    --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
  }
  input[type="checkbox"]:disabled,
  input[type="radio"]:disabled {
    --b: var(--disabled);
    cursor: not-allowed;
    opacity: 0.9;
  }
  input[type="checkbox"]:disabled:checked,
  input[type="radio"]:disabled:checked {
    --b: var(--disabled-inner);
    --bc: var(--border);
  }
  input[type="checkbox"]:disabled + label,
  input[type="radio"]:disabled + label {
    cursor: not-allowed;
  }
  input[type="checkbox"]:hover:not(:checked):not(:disabled),
  input[type="radio"]:hover:not(:checked):not(:disabled) {
    --bc: var(--border-hover);
  }
  input[type="checkbox"]:focus,
  input[type="radio"]:focus {
    box-shadow: 0 0 0 var(--focus);
  }
  input[type="checkbox"]:not(.switch),
  input[type="radio"]:not(.switch) {
    width: 25px;
    min-width: 25px;
  }
  input[type="checkbox"]:not(.switch):after,
  input[type="radio"]:not(.switch):after {
    opacity: var(--o, 0);
  }
  input[type="checkbox"]:not(.switch):checked,
  input[type="radio"]:not(.switch):checked {
    --o: 1;
  }
  input[type="checkbox"] + label,
  input[type="radio"] + label {
    font-size: 14px;
    line-height: 25px;
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
    margin-left: 4px;
  }
  input[type="checkbox"]:not(.switch) {
    border-radius: 7px;
  }
  input[type="checkbox"]:not(.switch):after {
    width: 7px;
    height: 12px;
    border: 2px solid var(--active-inner);
    border-top: 0;
    border-left: 0;
    left: 8px;
    top: 4px;
    transform: rotate(var(--r, 20deg));
  }
  input[type="checkbox"]:not(.switch):checked {
    --r: 43deg;
  }
  input[type="checkbox"].switch {
    width: 38px;
    border-radius: 11px;
  }
  input[type="checkbox"].switch:after {
    left: 2px;
    top: 2px;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    background: var(--ab, var(--border));
    transform: translateX(var(--x, 0));
  }
  input[type="checkbox"].switch:checked {
    --ab: var(--active-inner);
    --x: 17px;
  }
  input[type="checkbox"].switch:disabled:not(:checked):after {
    opacity: 0.6;
  }
  input[type="radio"] {
    border-radius: 50%;
  }
  input[type="radio"]:after {
    width: 19px;
    height: 19px;
    border-radius: 50%;
    background: var(--active-inner);
    opacity: 0;
    transform: scale(var(--s, 0.7));
  }
  input[type="radio"]:checked {
    --s: 0.5;
  }
}
ul {
  margin: 12px;
  padding: 0;
  list-style: none;
  width: 100%;
  max-width: 320px;
}
ul li {
  margin: 16px 0;
  position: relative;
}
html {
  box-sizing: border-box;
}
* {
  box-sizing: inherit;
}
*:before,
*:after {
  box-sizing: inherit;
}
body {
  min-height: 100vh;
  font-family: GothamBook;
  color: #8a91b4;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f6f8ff;
}
@media (max-width: 800px) {
  body {
    flex-direction: column;
  }
}
</style>
